@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
}

body {
    background-color: #f7f7f7;
    font-family: 'Overpass', sans-serif;}

.logo {
    width: 15rem;
    height: 3rem;
}

.nav-link, .nav-link-active {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
}

.nav-link:hover {
    color: #facf0f !important;
}

.nav-link-active {
    border-bottom: 2px solid #facf0f;
}

.underline {
    width: 3.5rem;
    height: 0.2rem;
    background-color: #facf0f;
}

.about-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.home {
    max-width: 100%;
    overflow-x: hidden;
    min-height: calc(100vh - 195px);
}

.home-img {
    width: 18rem;
    height: 18rem;
}

.home-text {
    color: #facf0f;
    font-size: 3.4rem;
    font-weight: 400;
    font-family: 'Pacifico', cursive !important;

}

.home-icon:hover {
    transform: scale(1.15);
}